.barhoppers {
    background-image: url("./assets/barhoppers.png");
}
.budgie {
    background-image: url("./assets/budgie.png");
    color: var(--black);
}
.regex {
    background-image: url("./assets/regex.png");
}
.ecommerce {
    background-image: url("./assets/ecommerce.png");
}
.workerlurker {
    background-image: url("./assets/workerlurker.png");
}
.readmeme {
    background-image: url("./assets/readmeme.png");
}
.biketrails {
    background-image: url("./assets/biketrails.png");
    color:var(--black);
}
.weather {
    background-image: url("./assets/weather.png");
}
.scheduler {
    background-image: url("./assets/scheduler.png");
}
.pwgen {
    background-image: url("./assets/pwgen.png");
}
