:root {
  --black: #0f0f0f;
  --dark: #2d2e2e;
  --white: #fbfbfb;
  --grey: #888;
}

/* a {
  color:var(--grey);
} */

.pageview {
  background-color: var(--dark);
  padding: 10px;
  min-height: 60vh;
  padding-top:25px;
}

.pagetext {
  color: var(--white);
}
.pagetext h2 {
  text-align: right;
}

.color-nav {
  background-color: var(--black);
}

.banner {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 30vh;
  width: 100%;
  background-image: url('./assets/skyline.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.pagecontent {
  border-left: 3px solid var(--white);
  padding-left: 25px;
}

.avatar {
  float: left;
  height: 12vh;
  margin-right: 1rem;
}

.pcard {
  margin-bottom: 25px;
}
.project {
  height: 150px;
  opacity: 1;
  background-size: cover;
}

.project:hover {
  opacity: 0.5;
}

.projectlabel {
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.4);
  color: var(--white);
  font-size: small;
  padding: 10px 10px 10px;
  opacity: 0;
  border-radius: 5px;
}
.projectlabel a {
  color: var(--white)
}
.github a {
  color: var(--white);
  font-size: xx-large;
}
.projectlabel:hover {
  opacity: 1;
}

footer {
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
}

footer div {
  width: 66%;
  display: flex;
  justify-content: space-around;
  padding: 0 20% 0 20%;
}

footer div a {
  color:var(--grey);
  font-size: xx-large;
  
}

@media screen and (max-width: 768px) {
  .avatar {
    height: 75px;
  }
  .pageview{
    padding-top: 10px;
  }
  .pagecontent {
    border-left: 0px;
    border-top: 3px solid var(--white);
    padding: 10px 0 0 0;
  }
  .pagetext {
    display: flex;
    flex-direction: column;
  }
}